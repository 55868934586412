import React from "react"

const SpotifyLogo = ({ style, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508.52 508.52"
    style={style || { stroke: "black", fill: "black" }}
    {...props}>
    <g>
      <path d="M254.26 0C113.845 0 0 113.845 0 254.26s113.845 254.26 254.26 254.26 254.26-113.845 254.26-254.26C508.52 113.813 394.675 0 254.26 0zm0 444.955c-105.327 0-190.695-85.368-190.695-190.695S148.933 63.565 254.26 63.565s190.695 85.368 190.695 190.695-85.368 190.695-190.695 190.695z" />{" "}
      <path d="M384.568 201.787c-50.089-24.981-103.96-37.63-160.247-37.63-29.303 0-58.48 3.56-86.798 10.584-9.376 2.32-15.065 13.635-12.745 22.979 2.32 9.344 11.791 15.065 21.167 12.745 25.553-6.325 51.933-9.535 78.407-9.535 50.788 0 99.416 11.41 144.61 33.944a17.366 17.366 0 0 0 7.755 1.843c6.452 0 12.586-5.371 15.701-11.505 4.291-8.614.795-19.134-7.85-23.425zM224.321 311.977c-23.614 0-46.943 3.464-69.349 10.425-6.929 2.066-10.774 16.749-8.645 23.71 2.129 6.897 9.471 10.806 16.4 8.613 19.864-6.166 40.618-9.28 61.594-9.28 35.024 0 69.699 8.867 100.242 25.776l6.325 1.621c4.64 0 9.122-2.479 11.442-6.833 3.464-6.325 1.24-21.676-5.149-25.14-34.389-18.881-73.418-28.892-112.86-28.892zM360.064 273.615c-41.635-21.612-88.546-33.086-135.711-33.086-26.316 0-52.505 3.496-77.772 10.393-8.136 2.225-12.935 16.114-10.711 24.25 2.225 8.136 10.615 12.904 18.783 10.711a265.39 265.39 0 0 1 69.699-9.312c42.906 0 83.779 9.948 121.663 29.621 2.225 1.176 4.64 1.78 7.024 1.78 5.562 0 10.87-8.581 13.603-13.794 3.815-7.468.891-16.717-6.578-20.563z" />
    </g>
  </svg>
)

export default SpotifyLogo
